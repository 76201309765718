<template>
    <div class="container padding-container">
        <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="handleDialogShow('')">新增渠道</el-button>
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="2" :sm="4" :xs="8">
                    <span class="list-title">渠道列表</span>
                </el-col>
            </el-row>
            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" :row-key="(row) => row.id">
                    <!-- <el-table-column type="selection" width="60" :reserve-selection="true" align="center"></el-table-column> -->
                    <el-table-column prop="name" label="渠道名称" min-width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.name || '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="创建方" min-width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.school_id | creatorTypeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="150" v-if="userType !== USER_TYPE.PROVIDER">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="handleDialogShow(scope.row)">
                                <el-tooltip placement="top" content="编辑">
                                    <i class="iconfont icon-edit" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)" v-if="canDelete(scope.row.school_id)">
                                <el-tooltip placement="top" content="删除">
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currentPage" :page-sizes="[total, 10, 15, 20, 25]"
                    :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" @close="handleDialogClose" class="activity-channel-dialog" width="500px">
            <div class="dialog-content">
                <div class="form-item">
                    <div class="key">渠道名称</div>
                    <el-input class="value" v-model="editForm.name" placeholder="请输入渠道名称"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display:flex;justify-content: center;">
                <el-button @click="handleDialogClose">取 消</el-button>
                <el-button type="primary" @click="handleDialogConfirm">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE;

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            org_id: '',
            school_id: '',
            userType: Core.Data.getUserType(),
            USER_TYPE,

            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],

            title: '',
            dialogVisible: false,
            editForm: {
                id: '',
                name: '',
            },
        };
    },
    filters: {
        creatorTypeFilter(val) {
            if (Core.Data.getUserType() === USER_TYPE.SCHOOL) {
                let self_id = Core.Data.getBelong().id
                switch (val) {
                    case 0: return '机构方'
                    case self_id: return '己方园区'
                    default: return '其他园区'
                }
            } else {
                switch (val) {
                    case 0: return '机构方'
                    default: return '园区方'
                }
            }
        },
    },
    created() {
        if (this.userType === USER_TYPE.SCHOOL) {
            this.school_id = Core.Data.getBelong().id
            console.log('mounted this.school_id:', this.school_id)
        }
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() {
            Core.Api.OperSet.channelList(
                this.currentPage,
                this.pageSize
            ).then((res) => {
                console.log('getTableData res:', res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        handleDialogShow(item) {
            if (item) {
                this.title = '编辑渠道';
                this.editForm.id = item.id;
                this.editForm.name = item.name;
            } else {
                this.title = '新增渠道';
            }
            this.dialogVisible = true;
        },
        handleDialogClose() {
            this.dialogVisible = false;
            this.editForm = {
                id: '',
                name: '',
            };
        },
        handleDialogConfirm() {
            console.log('editForm:', this.editForm);
            if (!this.editForm.name) {
                return this.$message.warning('请输入渠道名称');
            }
            Core.Api.OperSet.channelSave(
                this.editForm.id,
                this.editForm.name,
            ).then(() => {
                this.$message.success('保存成功');
                this.getTableData();
                this.handleDialogClose();
            });
        },
        handleDelete(id) {
            console.log('id', id)
            this.$confirm('确定要删除吗？', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then((res) => {
                Core.Api.OperSet.channelDelete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.getTableData();
                });
            });
        },
        canDelete(school_id) {
            if (this.userType === USER_TYPE.ORG) {
                return true
            } else if (this.userType === USER_TYPE.SCHOOL) {
                if (school_id === this.school_id) {
                    return true
                }
            }
            return false
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}

.activity-channel-dialog {
    .form-item {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        .key {
            width: 72px;
        }
        .value {
            width: calc(100% - 72px);
        }
    }
}
</style>
